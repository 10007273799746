// Fix for old jquery.autocomplete
if (typeof $ !== 'undefined' && typeof $.browser === 'undefined') {
  $.browser = {
    msie: false,
  };
}

// Temporary fix for missing ba-bbq.js functions TODO Need to fix this properly
if (typeof $ !== 'undefined' && typeof $.param.querystring === 'undefined') {
  $.param.querystring = function (url) {
    return url;
  };
}
